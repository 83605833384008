.App {
  text-align: center;
  max-width: 1920px !important;
  margin: 0 auto !important;
}

.App-logo {
  width: 200px !important;
  margin-left: 20px;
  margin-right: 10px;
  align-items: center;
}

.App-connect {
  margin-right: 20px;
  margin-left: 30px;
}

.card-header {
  background-color: #282c34 !important;
  color: white !important;
}

.card-body {
  background-color: #646464 !important;
  color: white !important;
}

.btn-primary {
  border-color: none !important;
  border-radius: 30px !important;
}

.btn-seconday {
  background-color: #18ddf7 !important;
  border-color: none !important;
}

.contain-header {
  color: white !important;
}

.contain-body {
  background-color: rgba(217, 217, 217, 0.05) !important;
  color: white !important;
  border-radius: 20px;
}

.title-contain {
  font-weight: 600;
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 30px;
}

.btn-custom {
  min-width: 140px !important;
  border-radius: 30px !important;
  background-color: #17A6CE !important;
  color: white !important;
  cursor: pointer;
}

.contain-body p {
  font-size: 16px;
  line-height: 24px;
}

/* sale-infor */
.sale-infor {
  margin-bottom: 40px;
  margin-top: 30px;
}

.info-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px;
}

.info-item {
  flex: 1;
  margin: 10px;
  min-width: 200px;  /* you can adjust this */
  font-weight: 600;
  border-left: 1px solid #17A6CE;
}

.info-item:first-child{
  border-left: none;
}

.info-item-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}

.info-item-unit {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}

.info-item-contain {
  font-size: 36px;
  line-height: 48px;
}

/* user-infor */
.user-infor {
  margin-bottom: 50px;
}

@media (prefers-reduced-motion: no-preference) {

}

.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  color: white;
}

.App-link {
  color: #61dafb;
}

.max-text {
  position: absolute;
  top: 7px;
  right: 10px;
  cursor: pointer;
  color: blue;
}

.text-error {
  color: #18ddf7 !important;
}

.input-container {
  position: relative;
  width: 400px;
}

.input-box {
  width: 100%;
}

.switch-network  {
  font-size: 12px;
  text-align: left;
}
.switch-network a {
 text-decoration: underline !important;
}

.action-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* navbar */
.navbar-toggler {
  color: #17A6CE !important;
  background: white !important;
}

.navbar-toggler-icon {
  color: #17A6CE !important;
}

.nav-link:hover {
  background: white !important;
  color: #17A6CE !important;
}

.nav-link {
  color: white !important;
}

.navbar-toggler {
  background: white !important;
}

.nav-item {
  margin: 0 1em !important;
}

.menu {
  position: fixed;
  z-index: 11;
  width: 100%;
  background-color: black;
  transition:  1s ease-in-out;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  padding-top: 300px;
  color: white;
}

.header-menu{
  display: flex;
  align-items: center;
  padding: 1% 0% 2%;
}

.hidden-menu {
  min-height: 700px !important;
  margin-top: -1500px !important;
  position: fixed;
  z-index: 11;
  width: 100%;
  background-color: black;
  transition: 1s ease-in-out;
}

.content-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content-center div {
  width: 80%;
  text-align-last: right;
  height: 60px;
  border-bottom: 1px grey solid !important;
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  padding-bottom: 3%;
}

.content-center i {
  margin-left: 2%;
  color: #6a547f;
}

.content-center span {
  font-size: 18px;
  margin-top: 4.5%;
  margin-left: 4%;
}

.burger-menu {
  border: none;
  margin-right: 20px;
  background: transparent;
}

.close-menu {
  border: none;
  margin-right: 20px;
  background: black;
}

.close-menu i {
  font-size: 35px;
  color: #17A6CE;
}

.content-menu {
  padding-bottom: 30px;
}

.content-hover:hover {
  color: #6a547f;
  cursor: pointer;
}

.content-hover label:hover {
  cursor: pointer;
}

.text-content {
  color: white;
  text-decoration: none;
}

.text-content:hover {
  text-decoration: none;
}