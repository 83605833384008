body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  background-image: url("./images/background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  flex-direction: column;
  min-height: 100vh;
  background-attachment: fixed;
  background-position: left top;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Media query for tablet/ipad devices with min-width: 768px and max-width: 1024px */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {

}

/* Media query for tablet/ipad devices with max-width: 767px */
@media only screen and (max-device-width: 767px) {
  .App-connect {
    margin-right: 10px !important;
    margin-left: 0px !important;
  }
}